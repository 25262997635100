html {
    height: 100%;
}

body {
    background: url(../images/bg-tile.gif);
    font-family: Impact, 'Arial Black', sans-serif;
    font-size: 100%;
    line-height: 1;
    text-transform: uppercase;
}

#wrap {
    width: 960px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

h1,
h2 {
    margin: 0;
    font-size: 1rem;
}

h3 {
    font-size: 1.35em;
}

a,
button {
    color: $blue-dark;
    text-decoration: none;
    transition: opacity $time;
    >span,
    >img {
        backface-visibility: hidden;
    }
    &:hover,
    &:focus {
        opacity: 0.75;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.hocus {
    position: relative;
    opacity: 1 !important;
    img {
        transition: opacity $time;
    }
    &:hover img,
    &:focus img {
        opacity: 0.75;
    }
}

.hocus-reveal {
    display: none;
    position: absolute;
    font-size: 1rem;
    font-family: Arial, sans-serif;
    background: rgba(255, 255, 255, 0.98);
    top: 54px;
    left: -67%;
    padding: 0 20px;
    border: 3px solid #002d62;
    border-radius: 5px;
    text-align: center;
    text-transform: none;
    width: 320px;
}

.hocus-reveal::after {
    content: " ";
    display: block;
    height: 160px;
    position: absolute;
    top: 0;
}

.contact .hocus-reveal::after {
    width: 285px;
    left: -4px;
    top: -50px;
}

.hocus:hover .hocus-reveal,
.hocus:focus .hocus-reveal {
    display: block;
}

.stretch {
    transform: scaleX(1.5) translateX(-2em);
    letter-spacing: -0.015em;
    transform-origin: 0 0;
    display: inline-block;
    vertical-align: top;
    text-align: left;
}

header {
    position: relative;
    z-index: 5;
    background: url("../images/hero-background.png") no-repeat top center;
    background-size: 100% auto;
    background-color: transparent;
    width: 100%;
    height: 493px;
    background-position: 0 109px;
}

.catalogue {
    position: absolute;
    right: 1px;
    top: 75px;
}

.poster img {
    position: absolute;
    right: -223px;
    top: 15px;
}

.logo {
    display: inline-block;
}

.ext-links {
    float: right;
    text-align: right;
    width: 202px;
    right: 223px;
    position: absolute;
    top: 187px;
    a {
        display: block;
        color: $black;
    }
    .jango {
        margin: 15px;
    }
    .contact {
        margin: 10px 0 0 35px;
        width: 153px;
    }
}

main {
    position: relative;
    z-index: 100;
    margin-top: -14px;
    background: $white;
    &:before {
        left: 0;
    }
    &:after {
        right: 0;
    }
}

.search {
    background: $black-grey;
    >a {
        top: -34px;
        position: absolute;
        right: 337px;
    }
}

.coming-soon {
    background: rgba(255, 255, 255, 0.98);
    padding: 13px;
    width: 244px;
    font-family: arial;
    border-radius: 5px;
    border: 3px solid black;
    text-align: center;
    position: absolute;
    top: 13px;
    right: 497px;
    display: none;
}

.search a:hover~.coming-soon {
    display: block;
}

.search-nav {
    overflow: hidden;
}

form {
    display: block;
    width: 262px;
    height: 73px;
    float: right;
    margin: 0;
    background: $black-grey;
    border-radius: 25px;
    overflow: hidden;
    label {
        top: 6px;
        position: absolute;
        right: 14px;
    }
    label,
    input,
    button {
        display: block;
        margin: 0;
    }
    input {
        width: 223px;
        height: 77px;
        float: left;
        padding: 0px 20px;
        resize: none;
        border-radius: 0.75em;
        border: solid 1px $black-grey;
        font-family: Helvetica, Arial, sans-serif;
        font-size: 23px;
        text-transform: uppercase;
        color: $blue-dark;
        top: 40px;
        position: absolute;
        right: 8px;
        transition: background $time, color $time;
        &:focus {
            background: $light-grey;
            color: $black;
        }
    }
    button {
        background: 0;
        padding: 0;
        width: 86px;
        height: 57px;
        margin: 13px 6px 14px 0;
        float: right;
        border: none;
        box-shadow: none;
    }
}

.main-nav {
    width: 683px;
    float: left;
    padding: 0 5px;
    padding-bottom: 15px;
    a {
        width: 280px;
        height: 21px;
        display: inline-block;
        overflow: hidden;
        background: url(../images/button-sprites.gif) no-repeat;
        margin-top: 14px;
        margin-right: 37px;
        margin-left: 17px;
        &.active {
            opacity: 1 !important;
        }
    }
    [href="#hockey"] {
        background-position-y: 0;
        margin-top: 18px;
    }
    [href="#lacrosse"] {
        background-position-y: -110px;
        margin-top: 18px;
    }
    [href="#baseball"] {
        background-position-y: -37px;
    }
    [href="#ringette"] {
        background-position-y: -147px;
    }
    [href="#volleyball"] {
        background-position-y: -73px;
    }
    [href="#other"] {
        background-position-y: -183px;
    }
}

.content {
    background: $white;
    h2 {
        height: 43px;
        text-align: center;
        border-top: solid 1px $blue-dark;
        border-bottom: solid 1px $blue-dark;
        img {
            margin-top: 5px;
        }
    }
}

.grid {
    padding: 15px 0 30px;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    li {
        width: 33.3333%;
        margin: 16px 0;
        text-align: center;
        color: $blue-dark;
        font-family: Arial, sans-serif;
        font-size: 0.675em;
        line-height: 1.35;
        text-transform: none;
        display: none;
    }
    [data-type="no-result"],
    [data-type="other"] {
        font-size: 1em;
        width: 100%;
        margin: 30px 0 50px;
    }
    img {
        max-width: 100%;
        width: auto;
        height: auto;
        display: inline-block;
    }
    .details {
        padding: 0 0.15em 0.5em;
        margin: 0;
    }
}

#hockey [data-type="hockey"],
#lacrosse [data-type="lacrosse"],
#box-lacrosse [data-type="box-lacrosse"],
#football [data-type="football"],
#baseball [data-type="baseball"],
#field-lacrosse [data-type="field-lacrosse"],
#cycling [data-type="cycling"],
#ringette [data-type="ringette"],
#volleyball [data-type="volleyball"],
#t-shirts [data-type="t-shirts"],
#soccer [data-type="soccer"],
#motocross [data-type="motocross"],
#other [data-type="other"],
#no-result [data-type="no-result"],
#results .is-result {
    display: block;
}